/* eslint-disable no-unused-vars */
export default {
  namespaced: true,
  state: {
    permissions: [],
  },
  getters: {
    /**
     * Getter method to get the all the permissions available for this user
     * @param state
     * @returns {[]|*[]|Permissions}
     */
    getPermissions: (state) => state.permissions,
  },
  mutations: {
    UPDATE_PERMISSIONS(state, arr) {
      state.permissions = arr;
    },
  },
  actions: {
    updatePermissions({ commit, dispatch }, arr) {
      commit("UPDATE_PERMISSIONS", arr);
    },
  },
};

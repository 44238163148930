import { ROUTER_URL } from "../constants/urls";

const routes = [
  {
    path: ROUTER_URL.publicDonationPages.link,
    name: ROUTER_URL.publicDonationPages.name,
    component: () =>
      import(
        /* webpackChunkName: "PublicDonationPageLayout" */ "../layouts/PublicDonationPage/PublicDonationPageLayout.vue"
      ),
    meta: {
      name: ROUTER_URL.publicDonationPages.name,
      isPublic: true,
      title: ROUTER_URL.publicDonationPages.title,
      requiresAuth: false,
    },
    // children: [
    //   {
    //     path: ROUTER_URL.publicDonationPages.children.publicLandingPage.link,
    //     name: ROUTER_URL.publicDonationPages.children.publicLandingPage.name,
    //     component: () =>
    //       import(
    //         /* webpackChunkName: "PublicDonationPage" */ "../views/PublicDonationPage/PublicDonationPage.vue"
    //       ),
    //     meta: {
    //       isPublic: true,
    //       layout: "PublicLandingPageLayout",
    //       title: ROUTER_URL.publicDonationPages.children.publicLandingPage.title,
    //     },
    //   },
    // ],
  },
  {
    path: ROUTER_URL.publicParentDashboard.link,
    name: ROUTER_URL.publicParentDashboard.name,
    component: () =>
      import(
        /* webpackChunkName: "PublicDonationPageLayout" */ "../layouts/PublicDonationPage/PublicDonationPageLayout.vue"
        ),
    meta: {
      name: ROUTER_URL.publicParentDashboard.name,
      isPublic: true,
      title: ROUTER_URL.publicParentDashboard.title,
      requiresAuth: true,
    },

  },
];
export default routes;

export const TOKEN = "token";
export const OTP_TOKEN = "otp-token";
export const STATUS = "status";
export const NAME = "name";
export const FIRST_NAME = "first_name";
export const LAST_NAME = "last_name";
export const MOBILE = "mobile";
export const EMAIL = "email";
export const PASSWORD = "password";
export const OTP = "otp";
export const ID = "id";
export const DATA = "data";
export const MESSAGE = "message";
export const HEADERS = "headers";
export const TOTAL_PAGES = "total_pages";
export const QUERY = "query";
export const SEARCH_QUERY = "search_query";
export const SEARCH_RESULTS = "search_results";
export const TOTAL_LENGTH = "total_length";
export const HOST_NAME = "user_name";
export const HOST_EMAIL = "user_email";

//DATA-TABLE KEYs
export const PAGE_INFO = "page_info";
export const ITEMS_PER_PAGE = "detail_count";
export const PAGE_NUMBER = "page_number";
export const TOTAL_PAGE = "total_page";
export const SEARCH = "search";
export const SORTING_KEY = "sorting_key";
export const CURRENT_PAGE = "current_page";

// DISTRICT
export const DISTRICT_LIST = "district_list";

//SCHOOL
export const SCHOOL_LIST = "school_list";
export const SCHOOL_ID = "school_id";
//CAMPAIGN
export const CAMPAIGN_LIST = "campaign_list";
export const CAMPAIGN_TYPE = "campaign_type";
export const CAMPAIGN_ID = "campaign_id";
export const CAMPAIGN_DETAIL = "campaign_detail";
//TEACHER
export const TEACHER_LIST = "teacher_list";
export const TEACHER_ID = "teacher_id";

//STUDENT
export const STUDENT_LIST = "student_list";

//SPONSOR
export const SPONSOR_LIST = "sponsor_list";

//TEAM
export const TEAM_LIST = "team_list";
export const TEAM_LISTS = "team_lists";
export const USER_LIST = "user_list";

//DONATION
export const DONATION_LIST = "donation_list";

//PUBLIC DONATION PAGES
export const  SUB_DOMAIN = 'sub_domain'

export const GAME_ID = "game_id";
<template>
  <v-app>
    <SnackBar />
    <router-view />
  </v-app>
</template>

<script>
import { mapActions } from "vuex";
import SnackBar from "./components/SnackBar/SnackBar";

export default {
  name: "App",
  // metaInfo() {
  //   return {
  //     meta: [
  //       { name: 'description', content:  'Epiloge is about connecting in your field of interest. Our vision is to help people share their knowledge, work, projects, papers and ideas and build their network through what they do rather where they live, study or work.'},
  //       { property: 'og:title', content: "Epiloge - Build your network in your field of interest"},
  //       { property: 'og:site_name', content: 'Epiloge'},
  //       {property: 'og:type', content: 'website'},
  //       {name: 'robots', content: 'index,follow'}
  //     ]
  //   }
  // },
  components: {
    SnackBar,
  },
  data: () => ({
    //
  }),
  methods: {
    ...mapActions({ showToast: "snackBar/showToast" }),
  },
};
</script>
<style>
@font-face {
  font-family: "LubalGraph Bd BT";
  src: url("//db.onlinewebfonts.com/t/6bbd766887dfd12d186737afe2a14bf7.eot");
  src: url("//db.onlinewebfonts.com/t/6bbd766887dfd12d186737afe2a14bf7.eot?#iefix")
      format("embedded-opentype"),
    url("//db.onlinewebfonts.com/t/6bbd766887dfd12d186737afe2a14bf7.woff2")
      format("woff2"),
    url("//db.onlinewebfonts.com/t/6bbd766887dfd12d186737afe2a14bf7.woff")
      format("woff"),
    url("//db.onlinewebfonts.com/t/6bbd766887dfd12d186737afe2a14bf7.ttf")
      format("truetype"),
    url("//db.onlinewebfonts.com/t/6bbd766887dfd12d186737afe2a14bf7.svg#LubalGraph Bd BT")
      format("svg");
}
#app{
  background-color: #f7f7f7;
}

</style>

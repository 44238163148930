import Vue from "vue";
import Vuex from "vuex";

import mainStore from "./main/mainStore";
import snackBar from "./modules/SnackBar";
import campaign from "./modules/Campaign";
import districtManagement from "./modules/DistrictManagement";
import schoolManagement from "./modules/SchoolManagement";
import campaignManagement from "./modules/CampaignManagement";
import teacherManagement from "./modules/TeacherManagement";
import studentManagement from "./modules/StudentManagement";
import sponsorManagement from "./modules/SponsorManagement";
import teamManagement from "./modules/TeamManagement";
import donationManagement from "./modules/DonationManagement";
import publicDonationPage from "./modules/PublicDonationPage";
import prizeManagement from "./modules/PrizeManagement";
import emailSettings from "./modules/EmailSettings";
import peTeacher from "./modules/peTeacher"
import videoSection from "./modules/VideoSection"
import gameManagement from "./modules/GameManagement"
import calendarManagement from "./modules/CalendarManagement"
import deleteManagement from "./modules/DeleteManagement"
import resourceCommon from "./modules/ResourceCommon"

Vue.use(Vuex);

const getDefaultState = () => {
  return {
    isLoggedIn: false,
  };
};

// initial state
const state = getDefaultState();

const actions = {
  resetStoreState({ commit }) {
    commit("RESET_STATE");
  },
};

const mutations = {
  RESET_STATE(state) {
    Object.assign(state, getDefaultState());
  },
};

export default new Vuex.Store({
  state: state,
  mutations: mutations,
  actions: actions,
  modules: {
    mainStore,
    snackBar,
    districtManagement,
    schoolManagement,
    campaignManagement,
    teacherManagement,
    studentManagement,
    sponsorManagement,
    teamManagement,
    donationManagement,
    campaign,
    publicDonationPage,
    prizeManagement,
    emailSettings,
    peTeacher,
    videoSection,
    gameManagement,
    calendarManagement,
    deleteManagement,
    resourceCommon
  },
});


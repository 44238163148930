export default {
  namespaced: true,
  state: {
    addEditViewModal: {
      type: "", // add || edit || view
      show: false,
      teamID: "",
    },
    addTeamModal: {
      type: "", // add || edit || view
      show: false,
      teamID: "",
    },
    deleteModal:{
      teamID:"",
      Delete:false
    },
    show: false,
    Delete:false
  },
  getters: {
    getShow: (state) => state.addEditViewModal.show,
    getShowTeam: (state) => state.addTeamModal.show,
    getDelete:(state) => state.deleteModal.Delete,
  },
  mutations: {
    TOGGLE_MODAL(state, obj) {
      if (obj.show) {
        state.addEditViewModal.type = obj.type;
        state.addEditViewModal.teamID = obj.id ? obj.id : "";
      }
      state.addEditViewModal.show = obj.show;
    },
    TOGGLE_TEAM_MODAL(state, obj) {
      if (obj.show) {
        state.addTeamModal.type = obj.type;
        state.addTeamModal.teamID = obj.id ? obj.id : "";
      }
      state.addTeamModal.show = obj.show;
    },
    TOGGLE_DELETE_MODAL(state,obj){
      if (obj.Delete){
        state.deleteModal.teamID = obj.id ? obj.id :"";
      }
      state.deleteModal.Delete = obj.Delete
    }
  },
  actions: {
    toggleModal({ commit }, obj) {
      commit("TOGGLE_MODAL", obj);
    },
    toggleTeamModal({ commit }, obj) {
      commit("TOGGLE_TEAM_MODAL", obj);
    },
    toggleDeleteModal({commit},obj){
      commit("TOGGLE_DELETE_MODAL",obj)
    }
  },
};
export default {
    namespaced: true,
    state: {
      deleteModal: {
        show: false,
        deleteID: "",
      },
      show: false,
    },
    getters: {
      getShow: (state) => state.deleteModal.show,
    },
    mutations: {
      TOGGLE_MODAL(state, obj) {
        if (obj.show) {
          state.deleteModal.deleteID = obj.id ? obj.id : "";
        }
        state.deleteModal.show = obj.show;
      },
    },
    actions: {
      toggleModal({ commit }, obj) {
        commit("TOGGLE_MODAL", obj);
      },
    },
  };
  
export default {
  namespaced: true,
  state: {
    campaign: {},
    user: {},
    selectedStudent: {},
    makeDonationModal: {
      show: false,
      autoSelect: false,
    },
    thankYouModal: {
      show: false,
    },
    shareFundraiserModal: {
      show: false,
    },
    parentSignUpModal: {
      show: false,
      signUpPageFlag: false,
    },
    parentVerifyEmailModal: {
      show: false,
      email: '',
    },
    selectStudentModal: {
      show: false,
    },
    resetPasswordModal: {
      show: false,
    },
    createStudentModal: {
      show: false,
      type: '',
      studentID:null,
      actionType:""
    },
    oneTimeVideoModal: {
      type: "", // add || edit || view
      show: false,
    },
    inviteViaMail:{
      show:false,
      emailDetail:{}
    },
    inviteViaSms:{
      show:false,
      emailDetail:{}
    },
    duplicateStudentAlert:{
      show:false,
      data: {}
    },
    inviteViaMailPlayer:{
      show:false,
      playerDetail:{},
      playerId:null,
      type:""
    },
    createTeamModal: {
      type: "", // add || edit || view
      show: false,
    },
    alertMessageModal: {
      show: false,
    },
  },
  getters: {
    getPlayer:(state)=>state.inviteViaMailPlayer.show,
    getCampaign: (state) => {
      return state.campaign;
    },
    getMakeDonationAutoSelect: (state) => {
      return state.makeDonationModal.autoSelect;
    },
    getEmailDetail:(state)=>state.inviteViaMail.emailDetail,
    getInviteViaMail:(state)=>state.inviteViaMail.show,
    getInviteViaSms:(state)=>state.inviteViaSms.show,
    getMakeDonationModalShow: (state) => state.makeDonationModal.show,
    getThankYouModalShow: (state) => state.thankYouModal.show,
    getAlertMessageModalShow: (state) => state.alertMessageModal.show,
    getShareFundraiserModalShow: (state) => state.shareFundraiserModal.show,
    getParentSignUpModalShow: (state) => state.parentSignUpModal.show,
    getSignUpPageFlag: (state) => state.parentSignUpModal.signUpPageFlag,
    getParentVerifyEmailModalShow: (state) => state.parentVerifyEmailModal.show,
    getParentVerifyEmailModalEmail: (state) => state.parentVerifyEmailModal.email,
    getResetPasswordModalShow: (state) => state.resetPasswordModal.show,
    getCreateStudentModalShow: (state) => state.createStudentModal.show,
    getOneTimeVideoModalShow: (state) => state.oneTimeVideoModal.show,
    getUserDetails: (state) => state.user,
    getSelectStudentModalShow: (state) => state.selectStudentModal.show,
    getSelectedStudent: (state) => state.selectedStudent,
    getDuplicateStudentAlert:(state)=>state.duplicateStudentAlert.show,
    getDuplicateStudentAlertData:(state)=>state.duplicateStudentAlert.data,
    getShowTeam: (state) => state.createTeamModal.show,
  },
  mutations: {
    UPDATE_PLAYER(state,obj){
      if(obj.show){
        state.inviteViaMailPlayer.playerDetail = obj.detail?obj.detail:{};
        state.inviteViaMailPlayer.playerId = obj.id?obj.id:null;
        state.inviteViaMailPlayer.type = obj.type
      }
      state.inviteViaMailPlayer.show = obj.show
    },
    UPDATE_CAMPAIGN(state, obj) {
      state.campaign = obj;
    },
    TOGGLE_MAKE_DONATION_MODAL(state, obj) {
      if (obj.show) {
        state.makeDonationModal.autoSelect = obj.autoSelect;
      }
      state.makeDonationModal.show = obj.show;
    },
    TOGGLE_THANK_YOU_MODAL(state, obj) {
      state.thankYouModal.show = obj.show;
    },
    TOGGLE_ALERT_MESSAGE_MODAL(state, obj) {
      state.alertMessageModal.show = obj.show;
    },
    TOGGLE_SHARE_FUNDRAISER_MODAL(state, obj) {
      state.shareFundraiserModal.show = obj.show;
    },
    TOGGLE_PARENT_SIGN_UP_MODAL(state, obj) {
      state.parentSignUpModal.show = obj.show;
      state.parentSignUpModal.signUpPageFlag = obj.signUpPageFlag?obj.signUpPageFlag:false;
    },
    TOGGLE_PARENT_VERIFY_EMAIL_MODAL(state, obj) {
      if (obj.show) state.parentVerifyEmailModal.email = obj.email;
      state.parentVerifyEmailModal.show = obj.show;
    },
    TOGGLE_RESET_PASSWORD_MODAL(state, obj) {
      state.resetPasswordModal.show = obj.show;
    },
    TOGGLE_CREATE_STUDENT_MODAL(state, obj) {
      state.createStudentModal.show = obj.show;
      state.createStudentModal.type = obj.type ? obj.type : ''
      state.createStudentModal.studentID = obj.id? obj.id:null
      state.createStudentModal.actionType = obj.actionType? obj.actionType:""
    },

    UPDATE_USER_DETAILS (state, obj) {
      state.user = obj.user;
      console.log('state.user: ', state.user);
    },
    TOGGLE_SELECT_STUDENT_MODAL(state, obj) {
      state.selectStudentModal.show = obj.show;
    },
    UPDATE_SELECTED_STUDENT (state, obj) {
      state.selectedStudent = obj.student;
    },
    UPDATE_INVITE_MAIL(state,obj){
      state.inviteViaMail.show = obj.show
    },
    UPDATE_INVITE_SMS(state,obj){
      state.inviteViaSms.show = obj.show
    },
    UPDATE_INVITE_MAIL_DETAIL(state,obj){
      state.inviteViaMail.emailDetail = obj.emailDetail
    },
    TOGGLE_DUPLICATE_STUDENT_ALERT_MODAL(state,obj){
      if (obj.show) state.duplicateStudentAlert.data = obj.data
      state.duplicateStudentAlert.show = obj.show
    },
    TOGGLE_TEAM_MODAL(state, obj) {
      if (obj.show) {
        state.createTeamModal.type = obj.type;
      }
      state.createTeamModal.show = obj.show;
    },
    TOGGLE_ONE_TIME_VIDEO_MODAL(state, obj) {
      if (obj.show) {
        state.oneTimeVideoModal.type = obj.type;
      }
      state.oneTimeVideoModal.show = obj.show;
    },
  },
  actions: {
    updateCampaign({ commit }, obj) {
      commit("UPDATE_CAMPAIGN", obj);
    },
    toggleMakeDonationModal({ commit }, obj) {
      commit("TOGGLE_MAKE_DONATION_MODAL", obj);
    },
    toggleThankYouModal({ commit }, obj) {
      commit("TOGGLE_THANK_YOU_MODAL", obj);
    },
    toggleAlertMessageModal({ commit }, obj) {
      commit("TOGGLE_ALERT_MESSAGE_MODAL", obj);
    },
    toggleShareFundraiserModal({ commit }, obj) {
      commit("TOGGLE_SHARE_FUNDRAISER_MODAL", obj);
    },
    toggleParentSignUpModal({ commit }, obj) {
      commit("TOGGLE_PARENT_SIGN_UP_MODAL", obj);
    },
    toggleParentVerifyEmailModal({ commit }, obj) {
      commit("TOGGLE_PARENT_VERIFY_EMAIL_MODAL", obj);
    },
    toggleResetPasswordModal({ commit }, obj) {
      commit("TOGGLE_RESET_PASSWORD_MODAL", obj);
    },
    toggleCreateStudentModal({ commit }, obj) {
      commit("TOGGLE_CREATE_STUDENT_MODAL", obj);
    },
    toggleOneTimeVideoModal({ commit }, obj) {
      commit("TOGGLE_ONE_TIME_VIDEO_MODAL", obj);
    },
    updateUserDetails({ commit }, obj) {
      commit("UPDATE_USER_DETAILS", obj)
    },
    toggleSelectStudentModal({ commit }, obj) {
      commit("TOGGLE_SELECT_STUDENT_MODAL", obj);
    },
    updateSelectedStudent({ commit }, obj) {
      commit("UPDATE_SELECTED_STUDENT", obj)
    },
    toggleInviteMail({commit},obj){
      commit("UPDATE_INVITE_MAIL",obj)
    },
    toggleInviteSms({commit},obj){
      commit("UPDATE_INVITE_SMS",obj)
    },
    togglePlayerModal({commit},obj){
      commit("UPDATE_PLAYER",obj)
    },
    toggleStoreDetail({commit},obj){
      commit("UPDATE_INVITE_MAIL_DETAIL",obj)
    },
    toggleDuplicateStudentAlertModal({commit},obj){
      commit("TOGGLE_DUPLICATE_STUDENT_ALERT_MODAL",obj)
    },
    toggleTeamModal({ commit }, obj) {
      commit("TOGGLE_TEAM_MODAL", obj);
    },
  },
};

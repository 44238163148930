export default {
    namespaced: true,
    state: {
      addEditViewModal: {
        type: "", // add || edit || view
        show: false,
        studentID: "",
      },
      deleteModal:{
        studentID:"",
        Delete:false
      },
      show: false,
      Delete:false

    },
    getters: {
      getShow: (state) => state.addEditViewModal.show,
      getDelete:(state) => state.deleteModal.Delete,
    },
    mutations: {
      TOGGLE_MODAL(state, obj) {
        if (obj.show) {
          state.addEditViewModal.type = obj.type;
          state.addEditViewModal.studentID = obj.id ? obj.id : "";
        }
        state.addEditViewModal.show = obj.show;
      },
      TOGGLE_DELETE_MODAL(state,obj){
        if (obj.Delete){
          state.deleteModal.studentID = obj.id ? obj.id :"";
        }
        state.deleteModal.Delete = obj.Delete
      }
    },
    actions: {
      toggleModal({ commit }, obj) {
        commit("TOGGLE_MODAL", obj);
      },
      toggleDeleteModal({commit},obj){
        commit("TOGGLE_DELETE_MODAL",obj)
      }
    },
  };
  
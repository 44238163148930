export default {
  namespaced: true,
  state: {
    addEditViewModal: {
      type: "", // add || edit || view
      show: false,
      districtID: "",
    },
    districtEditModal: {
      show: false,
      districtID: "",
    },
    show: false,
  },
  getters: {
    getShow: (state) => state.addEditViewModal.show,
    getEditDistrict:(state)=>state.districtEditModal.show
  },
  mutations: {
    TOGGLE_MODAL(state, obj) {
      if (obj.show) {
        state.addEditViewModal.type = obj.type;
        state.addEditViewModal.districtID = obj.id ? obj.id : "";
      }
      state.addEditViewModal.show = obj.show;
    },
    EDIT_TOGGLE_MODAL(state,obj){
      if(obj.show){
        state.districtEditModal.districtID = obj.id? obj.id :"";
      }
      state.districtEditModal.show = obj.show
    }
  },
  actions: {
    toggleModal({ commit }, obj) {
      commit("TOGGLE_MODAL", obj);
    },
    toggleEditModal({commit},obj){
      commit("EDIT_TOGGLE_MODAL",obj)
    }
  },
};

export default {
    namespaced: true,
    state: {
      addEditViewModal: {
        type: "", // add || edit || view
        show: false,
        emailID: "",
        detail:{}
      },
      deleteModal:{
        emailID:"",
        Delete:false
      },
      composedEmailDetail:{},
      show: false,
      Delete:false
    },
    getters: {
      getDelete:(state) => state.deleteModal.Delete,
      getShow: (state) => state.addEditViewModal.show,
      getDetail:(state)=>state.addEditViewModal.detail,
      getComposeEmailDetail:(state)=>state.composedEmailDetail
    },
    mutations: {
      TOGGLE_MODAL(state, obj) {
        if (obj.show) {
          state.addEditViewModal.type = obj.type;
          state.addEditViewModal.emailID = obj.id ? obj.id : "";
          state.addEditViewModal.detail = obj.detail ? obj.detail :"";
          
        }
        state.addEditViewModal.show = obj.show;
      },
      SET_EMAIL_DETAIL(state,obj){
        if (obj){
          state.composedEmailDetail = obj
        }
      },
      CLEAR_EMAIL_DETAIL(state){
        state.composedEmailDetail ={}
      },
      TOGGLE_DELETE_MODAL(state,obj){
        if (obj.Delete){
          state.deleteModal.emailID = obj.id ? obj.id :"";
        }
        state.deleteModal.Delete = obj.Delete
      }
    },
    actions: {
      toggleDeleteModal({commit},obj){
        commit("TOGGLE_DELETE_MODAL",obj)
      },
      toggleModal({ commit }, obj) {
        commit("TOGGLE_MODAL", obj);
      },
      toggleEmailDetail({commit},obj){
        commit("SET_EMAIL_DETAIL",obj)
      },
      toggleClearEmailDetail({commit}){
        commit("CLEAR_EMAIL_DETAIL")
      }
    },
  };
  
export default {
  namespaced: true,
  state: {
    switchCampaignModal: {
      show: false,
    },
    selectedCampaign: {},
    selectedCampaignBadges: { show: false,}
  },
  getters: {
    getSwitchCampaignModalShow: (state) => state.switchCampaignModal.show,
    getSelectedCampaign: (state) => {
      return state.selectedCampaign;
    },
    getSelectedCampaignBadgesshow: (state) => state.selectedCampaignBadges.show,
  },
  mutations: {
    TOGGLE_MODAL(state, obj) {
      state.switchCampaignModal.show = obj.show;
    },
    UPDATE_SELECTED_CAMPAIGN(state, obj) {
      state.selectedCampaign = obj;
      console.log('UPDATE_SELECTED_CAMPAIGN: ', state.selectedCampaign);

    },
    TOGGLE_BADGES_MODAL(state, obj) {
      state.selectedCampaignBadges.show = obj.show;
    },
  },
  actions: {
    toggleModal({ commit }, obj) {
      commit("TOGGLE_MODAL", obj);
    },
    updateSelectedCampaign({ commit }, obj) {
      commit("UPDATE_SELECTED_CAMPAIGN", obj);
    },
    toggleBadgesModal({ commit }, obj) {
      commit("TOGGLE_BADGES_MODAL", obj);
    },
  },
};

// COMMON KEYs
export const ITEMS = "items";
export const LOADING = "loading";

export const SUPER_ADMIN = "super_admin";
export const SALES_REPRESENTATIVE = "sales representative";
export const SALES_REP = "sales_rep";
export const STUDENT = "student";
export const TEACHER = "teacher";
export const PARENT = "parent";
export const SCHOOL = "school";
export const DISTRICT = "district";
export const PE_TEACHER = "pe"
export const PRINCIPAL = "principal"
export const EVENT_SUPPORT = "event_support"
export const GAME = "game"
//PARENT AUTH
export const INITIAL_ZERO_STUDENT = "initialZeroStudentInList"



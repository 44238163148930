export default {
    namespaced: true,
    state: {
      deleteResourceModal:{
        Delete:false,
        resourceID:"",
        
      },
    },
    getters: {
      getResourceDelete:(state) => state.deleteResourceModal.Delete,
    },
    mutations: {
      TOGGLE_DELETE_MODAL(state,obj){
        if (obj.Delete){
          state.deleteResourceModal.resourceID = obj.id ? obj.id :"";
        }
        state.deleteResourceModal.Delete = obj.Delete
      }
    },
    actions: {
      
      toggleDeleteResourceModal({commit},obj){
        commit("TOGGLE_DELETE_MODAL",obj)
      }
    },
  };
export default {
    namespaced: true,
    state: {
      addEventModal: {
        type: "", // add || edit || view
        abc:"",//pto
        show: false,
        eventID: "",
      },
      addTaskModal: {
        type: "", // add || edit || view
        show: false,
        taskID:"",
      },
      addFilterModal: {
        type: "", // add || edit || view
        show: false
      },
      previewModal: {
        type: "", // add || edit || view
        show: false
      },
      listGameInfoModal: {
        type: "", // add || edit || view
        show: false,
        gameID:"",
      },
      listWizfitInfoModal: {
        type: "", // add || edit || view
        show: false,
        campaignID:"",
      },
      listWizfitGBModal: {
        type: "", // add || edit || view
        show: false,
        campaignID:"",
      },
      listWizfitGRModal: {
        type: "", // add || edit || view
        show: false,
        campaignID:"",
      },
      listAssemblyInfoModal: {
        type: "", // add || edit || view
        show: false,
        assemblyID:"",
      },
      listHotelInfoModal: {
        type: "", // add || edit || view
        show: false,
        hotelID:"",
      },
      listTaskInfoModal: {
        type: "", // add || edit || view
        show: false,
        taskID:"",
      },
      listPtoInfoModal: {
        type: "", // add || edit || view
        show: false,
        ptoID:"",
      },
      listCustomInfoModal: {
        type: "", // add || edit || view
        show: false,
        ptoID:"",
      },
      playerAvailability: {
        type: "", // add || edit || view
        show: false,
        playerID:"",
      },
      playerAvailabilityEvent: {
        type: "", // add || edit || view
        show: false,
        playerID:"",
        date:"",
      },
      playerAvailabilityEventEdit: {
        type: "", // add || edit || view
        show: false,
        playerID:"",
      },
      deleteModal:{
        ptoID:"",
        Delete:false
      },
      deletePtoModal:{
        ptoID:"",
        Delete:false
      },
      deleteHotelModal:{
        hotelID:"",
        Delete:false
      },
      deletePlayerEventModal:{
        eventID:"",
        Delete:false
      },
    },
    getters: {
      getShow: (state) => state.addEventModal.show,
      getShowTask: (state) => state.addTaskModal.show,
      getShowFilter: (state) => state.addFilterModal.show,
      getShowEvent: (state) => state.previewModal.show,
      getShowListGameInfo: (state) => state.listGameInfoModal.show,
      getShowListWizfitInfo: (state) => state.listWizfitInfoModal.show,
      getShowListWizfitGB: (state) => state.listWizfitGBModal.show,
      getShowListWizfitGR: (state) => state.listWizfitGRModal.show,
      getShowListAssemblyInfo: (state) => state.listAssemblyInfoModal.show,
      getShowListHotelInfo: (state) => state.listHotelInfoModal.show,
      getShowListTaskInfo: (state) => state.listTaskInfoModal.show,
      getShowListPtoInfo: (state) => state.listPtoInfoModal.show,
      getShowListCustomInfo: (state) => state.listCustomInfoModal.show,
      getPlayerAvailabilty: (state) => state.playerAvailability.show,
      getPlayerAvailabiltyEvent: (state) => state.playerAvailabilityEvent.show,
      getPlayerAvailabiltyEventEdit: (state) => state.playerAvailabilityEventEdit.show,
      getCustomEventDelete:(state) => state.deleteModal.Delete,
      getPtoDelete:(state) => state.deletePtoModal.Delete,
      getHotelDelete:(state) => state.deleteHotelModal.Delete,
      getPlayerEventDelete:(state) => state.deletePlayerEventModal.Delete,
    },
    mutations: {
      TOGGLE_MODAL(state, obj) {
        if (obj.show) {
          state.addEventModal.type = obj.type;
          state.addEventModal.abc = obj.abc;
          state.addEventModal.eventID = obj.id ? obj.id : "";
        }
        state.addEventModal.show = obj.show;
      },
      TOGGLE_TASK_MODAL(state, obj) {
        if (obj.show) {
          state.addTaskModal.type = obj.type;
          state.addTaskModal.taskID = obj.id ? obj.id : "";
        }
        state.addTaskModal.show = obj.show;
      },
      TOGGLE_FILTER_MODAL(state, obj) {
        if (obj.show) {
          state.addFilterModal.type = obj.type;
        }
        state.addFilterModal.show = obj.show;
      },
      TOGGLE_PREVIEW_MODAL(state, obj) {
        if (obj.show) {
          state.previewModal.type = obj.type;
        }
        state.previewModal.show = obj.show;
      },
      TOGGLE_LIST_GAME_MODAL(state, obj) {
        if (obj.show) {
          state.listGameInfoModal.type = obj.type;
          state.listGameInfoModal.gameID = obj.id ? obj.id : "";
        }
        state.listGameInfoModal.show = obj.show;
      },
      TOGGLE_LIST_WIZFIT_MODAL(state, obj) {
        if (obj.show) {
          state.listWizfitInfoModal.type = obj.type;
          state.listWizfitInfoModal.campaignID = obj.id ? obj.id : "";
        }
        state.listWizfitInfoModal.show = obj.show;
      },
      TOGGLE_LIST_WIZFIT_GB_MODAL(state, obj) {
        if (obj.show) {
          state.listWizfitGBModal.type = obj.type;
          state.listWizfitGBModal.campaignID = obj.id ? obj.id : "";
        }
        state.listWizfitGBModal.show = obj.show;
      },
      TOGGLE_LIST_WIZFIT_GR_MODAL(state, obj) {
        if (obj.show) {
          state.listWizfitGRModal.type = obj.type;
          state.listWizfitGRModal.campaignID = obj.id ? obj.id : "";
        }
        state.listWizfitGRModal.show = obj.show;
      },
      TOGGLE_LIST_ASSEMBLY_MODAL(state, obj) {
        if (obj.show) {
          state.listAssemblyInfoModal.type = obj.type;
          state.listAssemblyInfoModal.assemblyID = obj.id ? obj.id : "";
        }
        state.listAssemblyInfoModal.show = obj.show;
      },
      TOGGLE_LIST_HOTEL_MODAL(state, obj) {
        if (obj.show) {
          state.listHotelInfoModal.type = obj.type;
          state.listHotelInfoModal.hotelID = obj.id ? obj.id : "";
        }
        state.listHotelInfoModal.show = obj.show;
      },
      TOGGLE_LIST_TASK_MODAL(state, obj) {
        if (obj.show) {
          state.listTaskInfoModal.type = obj.type;
          state.listTaskInfoModal.taskID = obj.id ? obj.id : "";
        }
        state.listTaskInfoModal.show = obj.show;
      },
      TOGGLE_LIST_PTO_MODAL(state, obj) {
        if (obj.show) {
          state.listPtoInfoModal.type = obj.type;
          state.listPtoInfoModal.ptoID = obj.id ? obj.id : "";
        }
        state.listPtoInfoModal.show = obj.show;
      },
      TOGGLE_LIST_CUSTOM_MODAL(state, obj) {
        if (obj.show) {
          state.listCustomInfoModal.type = obj.type;
          state.listCustomInfoModal.customID = obj.id ? obj.id : "";
        }
        state.listCustomInfoModal.show = obj.show;
      },
      TOGGLE_PLAYER_AVAILABILITY_MODAL(state, obj) {
        if (obj.show) {
          state.playerAvailability.type = obj.type;
          state.playerAvailability.playerID = obj.id ? obj.id : "";
        }
        state.playerAvailability.show = obj.show;
      },
      TOGGLE_PLAYER_AVAILABILITY_EVENT_MODAL(state, obj) {
        if (obj.show) {
          state.playerAvailabilityEvent.type = obj.type;
          state.playerAvailabilityEvent.playerID = obj.id ? obj.id : "";
          state.playerAvailabilityEvent.date = obj.date ? obj.date : "";
        }
        state.playerAvailabilityEvent.show = obj.show;
      },
      TOGGLE_PLAYER_AVAILABILITY_EVENT_EDIT_MODAL(state, obj) {
        if (obj.show) {
          state.playerAvailabilityEventEdit.type = obj.type;
          state.playerAvailabilityEventEdit.playerID = obj.id ? obj.id : "";
        }
        state.playerAvailabilityEventEdit.show = obj.show;
      },
      TOGGLE_DELETE_CUSTOM_MODAL(state,obj){
        if (obj.Delete){
          state.deleteModal.ptoID = obj.id ? obj.id :"";
        }
        state.deleteModal.Delete = obj.Delete
      },
      TOGGLE_DELETE_PTO_MODAL(state,obj){
        if (obj.Delete){
          state.deletePtoModal.ptoID = obj.id ? obj.id :"";
        }
        state.deletePtoModal.Delete = obj.Delete
      },
      TOGGLE_DELETE_HOTEL_MODAL(state,obj){
        if (obj.Delete){
          state.deleteHotelModal.hotelID = obj.id ? obj.id :"";
        }
        state.deleteHotelModal.Delete = obj.Delete
      },
      TOGGLE_DELETE_PLAYER_EVENT_MODAL(state,obj){
        if (obj.Delete){
          state.deletePlayerEventModal.eventID = obj.id ? obj.id :"";
        }
        state.deletePlayerEventModal.Delete = obj.Delete
      },
    },
    actions: {
      toggleModal({ commit }, obj) {
        commit("TOGGLE_MODAL", obj);
      },
      toggleTaskModal({ commit }, obj) {
        commit("TOGGLE_TASK_MODAL", obj);
      },
      toggleFilterModal({ commit }, obj) {
        commit("TOGGLE_FILTER_MODAL", obj);
      },
      togglePreviewsModal({ commit }, obj) {
        commit("TOGGLE_PREVIEW_MODAL", obj);
      },
      toggleListGameInfoModal({ commit }, obj) {
        commit("TOGGLE_LIST_GAME_MODAL", obj);
      },
      toggleListWizfitInfoModal({ commit }, obj) {
        commit("TOGGLE_LIST_WIZFIT_MODAL", obj);
      },
      toggleListWizfitGBModal({ commit }, obj) {
        commit("TOGGLE_LIST_WIZFIT_GB_MODAL", obj);
      },
      toggleListWizfitGRModal({ commit }, obj) {
        commit("TOGGLE_LIST_WIZFIT_GR_MODAL", obj);
      },
      toggleListAssemblyInfoModal({ commit }, obj) {
        commit("TOGGLE_LIST_ASSEMBLY_MODAL", obj);
      },
      toggleListHotelInfoModal({ commit }, obj) {
        commit("TOGGLE_LIST_HOTEL_MODAL", obj);
      },
      toggleListTaskInfoModal({ commit }, obj) {
        commit("TOGGLE_LIST_TASK_MODAL", obj);
      },
      toggleListPtoInfoModal({ commit }, obj) {
        commit("TOGGLE_LIST_PTO_MODAL", obj);
      },
      toggleListCustomInfoModal({ commit }, obj) {
        commit("TOGGLE_LIST_CUSTOM_MODAL", obj);
      },
      togglePlayerAvailability({ commit }, obj) {
        commit("TOGGLE_PLAYER_AVAILABILITY_MODAL", obj);
      },
      togglePlayerAvailabilityEvent({ commit }, obj) {
        commit("TOGGLE_PLAYER_AVAILABILITY_EVENT_MODAL", obj);
      },
      togglePlayerAvailabilityEventEdit({ commit }, obj) {
        commit("TOGGLE_PLAYER_AVAILABILITY_EVENT_EDIT_MODAL", obj);
      },
      toggleDeleteModal({commit},obj){
        commit("TOGGLE_DELETE_CUSTOM_MODAL",obj)
      },
      toggleDeletePtoModal({commit},obj){
        commit("TOGGLE_DELETE_PTO_MODAL",obj)
      },
      toggleDeleteHotelModal({commit},obj){
        commit("TOGGLE_DELETE_HOTEL_MODAL",obj)
      },
      toggleDeletePlayerEventModal({commit},obj){
        commit("TOGGLE_DELETE_PLAYER_EVENT_MODAL",obj)
      },
    },
  };
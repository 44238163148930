import Vue from "vue";
import VueRouter from "vue-router";
import routes from "./routes";
import authToken from "../common/authToken";
import VueMeta from "vue-meta";
// import store from "../store";

Vue.use(VueRouter);
Vue.use(VueMeta);

function firewallRoute(to, from, next) {
  // Check if access token is correct or not
  const res = authToken.inspectAccessToken();
  if (!res.flagSession) {
    // Redirect to login page
    if (res.action.redirect) {
      next({ name: res.action.page });
    }
  }
}

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior: (to, from, savedPosition) =>
    new Promise(() => {
      const position = savedPosition || {};
      if (!savedPosition) {
        if (to.hash) {
          position.selector = to.hash;
        }
        if (to.matched.some((m) => m.meta.scrollToTop)) {
          position.x = 0;
          position.y = 0;
        }
      }
      window.scrollTo(position.x, position.y);
    }),
});

router.beforeEach((to, from, next) => {
  document.title = to.meta.title || "WizFit";
  if (!to.meta.isPublic) {
    // Access-Token Authentication required here
    firewallRoute(to, from, next);
  }
  next();
});

export default router;

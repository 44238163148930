import { ROUTER_URL } from "../constants/urls";

var secret = 'ZYWA9{c~T4M?Btn_nf4h]ZWs@"6*=WZQ2}LvQ}';
var resetPasswordSecret = 'ZYWA9{c~T4M?Btn_nf4h]ZWs@"6*=WZQ2}LvQ}';

const authToken = {
  getAccessToken: () => {
    let token = null;
    if (localStorage.getItem("token")) {
      token = localStorage.getItem("token");
    }
    return token;
  },
  decodedToken: () => {
    // const jwtDecode = require("jwt-decode");
    const jwt = require("jwt-simple");
    try {
      // Correct token
      // return jwtDecode(authToken.getAccessToken());
      return jwt.decode(authToken.getAccessToken(), secret, true);
    } catch (err) {
      // Invalid token
      return {};
    }
  },
  decodedTokenFromString: (token) => {
    // const jwtDecode = require("jwt-decode");
    const jwt = require("jwt-simple");
    try {
      // Correct token
      // return jwtDecode(token);
      return jwt.decode(token, secret, true);
    } catch (err) {
      // Invalid token
      return {};
    }
  },
  /**
   * Save access token in local storage
   * @param token
   */
  setAccessToken: (token) => {
    localStorage.setItem("token", token);
  },
  /**
   * Remove access token from local storage
   */
  removeAccessToken: () => {
    localStorage.removeItem("token");
    localStorage.clear();
  },
  /**
   * Inspect the access token, Track the status of token
   * @returns {*}
   */
  inspectAccessToken: () => {
    if (authToken.isCorrectAccessToken()) {
      return {
        flagSession: true,
        action: {
          redirect: false,
        },
      };
    } else {
      return {
        flagSession: false,
        action: {
          redirect: true,
          page: ROUTER_URL.auth.children.login.name,
        },
      };
    }
  },
  /**
   * Returns true if token is correct.
   * @returns {boolean}
   */
  isCorrectAccessToken: () => {
    const jwt = require("jwt-simple");
    try {
      jwt.decode(authToken.getAccessToken(), secret, true);
      // Correct token
      return true;
    } catch (err) {
      // Invalid x
      return false;
    }
  },
  /**
   * Returns true if user has changed password and not logging in for first time
   * @returns {boolean}
   */
  loginForFirstTime: () => {
    return authToken.decodedToken().is_password_changed;
  },
  /**
   * Decode reset password token
   * @param token
   */
  decodedResetPasswordTokenFromString: (token) => {
    const jwt = require("jwt-simple");
    try {
      // Correct token
      return jwt.decode(token, resetPasswordSecret, true);
    } catch (err) {
      // Invalid token
      return {};
    }
  },
  /**
   * Returns true if token is correct.
   * @param token
   * @returns {boolean}
   */
  isCorrectResetPasswordToken: (token) => {
    const jwt = require("jwt-simple");
    try {
      jwt.decode(token, resetPasswordSecret, true);
      // Correct token
      return true;
    } catch (err) {
      // Invalid x
      return false;
    }
  },
  /**
   * Returns true if user.is_eblity_admin
   * @returns {boolean}
   */
  // is_eblity_admin: () => {
  //   return authToken.decodedToken().is_eblity_admin;
  // },
};
export default authToken;
